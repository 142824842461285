import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonMenu,
  IonMenuButton,
  IonPage,
  IonRouterOutlet,
  IonSplitPane,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonTitle,
  IonToolbar,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { ellipse, square, triangle } from "ionicons/icons";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "../../theme/variables.css";
import Login from "../../pages/web/authentication/Login/Login";
import ToastProvider from "../../providers/ToastProvider/ToastProvider";
import { useMemo } from "react";
import ForgotPassword from "../../pages/web/authentication/ForgotPassword/ForgotPassword";
import ResetPassword from "../../pages/web/authentication/ResetPassword/ResetPassword";
import Page404 from "../../pages/web/authentication/404/Page404";

setupIonicReact();

const WebAppAuthentication: React.FC = () => {
  console.log("WEB APP");

  const toastProvider = useMemo(() => {
    return <ToastProvider />;
  }, []);

  return (
    <>
      <IonRouterOutlet id="main">
        <Route path="/login" component={Login} exact={true}></Route>
        <Route
          path="/login/forgot"
          component={ForgotPassword}
          exact={true}
        ></Route>
        <Route
          path="/login/reset"
          component={ResetPassword}
          exact={true}
        ></Route>
        <Route
          path="/login/forgot/email-confirm"
          component={ResetPassword}
          exact={true}
        ></Route>
        <Route component={Page404}></Route>
      </IonRouterOutlet>
      {toastProvider}
    </>
  );
};

export default WebAppAuthentication;
