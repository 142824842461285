import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSlides,
  IonSlide,
  IonGrid,
  IonCol,
  IonRow,
  IonIcon,
  IonSpinner,
  IonList,
  IonListHeader,
  IonSkeletonText,
  IonToast,
} from "@ionic/react";
import { useParams } from "react-router";
import SchedulerNavbar from "../../../../components/Scheduler/SchedulerNavbar/SchedulerNavbar";
import "./Login.css";

import React, { useMemo } from "react";
import Footer from "../../../../components/Checkout/Footer";
import {
  arrowForward,
  eye,
  eyeOff,
  navigateCircleOutline,
} from "ionicons/icons";

import { Geolocation } from "@capacitor/geolocation";
import Constants from "../../../../lib/Constants";
import API from "../../../../lib/API";
import _ from "underscore";
import DateUtils from "../../../../lib/DateUtils";
import PubSub from "../../../../lib/PubSub";
import Event from "../../../../lib/Event";
import MapUtils from "../../../../lib/MapUtils";
import WindowUtils from "../../../../lib/WindowUtils";
import StorageUtils from "../../../../lib/StorageUtils";
import plnSymbol from "../../../../assets/img/logo/pln_symbol.svg";
import carousel1 from "../../../../assets/img/backgrounds/login_carousel_1-min.jpg";
import carousel2 from "../../../../assets/img/backgrounds/login_carousel_2-min.jpg";
import carousel3 from "../../../../assets/img/backgrounds/login_carousel_3-min.jpg";
import carousel4 from "../../../../assets/img/backgrounds/login_carousel_4-min.jpg";
import {
  SignInWithApple,
  SignInWithAppleResponse,
  SignInWithAppleOptions,
} from "@capacitor-community/apple-sign-in";
import { Capacitor } from "@capacitor/core";
import StringUtils from "../../../../lib/StringUtils";
import { Link } from "react-router-dom";
import SEOHeaders from "../../../../components/SEOHeaders";
import SegmentUtils, { SegmentIdentify } from "../../../../lib/SegmentUtils";
import LinkUtils from "../../../../lib/LinkUtils";

class Login extends React.Component<{ history: any }> {
  state: any = {
    requestingLocation: false,
    allStores: [],
    googleMap: null,
    slide: 1,
  };

  googleMap: any = React.createRef();
  formSection: any = React.createRef();
  listSection: any = React.createRef();

  handleTransition() {
    const interval = setInterval(() => {
      let slide = this.state.slide;

      if (slide == 4) {
        slide = 1;
      } else {
        slide = slide + 1;
      }

      this.setState({
        slide,
      });
    }, 10000);

    this.setState({
      slideInterval: interval,
    });
  }

  async handleOAuthRedirect(payload: any) {
    if (!payload) {
      return;
    }

    if (payload?.redirect == "active_member") {
      // TODO: redirect with sign in, save token

      let token = payload?.authenticationToken
        ? payload?.authenticationToken
        : payload.authorizationToken;

      await StorageUtils.set("user_authorizationToken", token);

      let customer = payload?.customer ? payload?.customer : {};

      customer.email = payload.email;

      await StorageUtils.set("user_customer", customer);

      if (customer) {
        let props: SegmentIdentify = {
          email: customer?.email,
          customer_id: customer?._id,
          first_name: StringUtils.getFirstName(customer?.name),
          last_name: StringUtils.getLastName(customer?.name),
        };

        if (customer?.phone) {
          props.phone = customer?.phone;
        }

        if (customer?.userID) {
          props.id = customer?.userID;
        }

        SegmentUtils.identify(props);

        SegmentUtils.track("User Signin", { method: "password" });
      }

      this.props.history.push("/manage/home");

      return;
    } else {
      // TODO: define what to do with leads

      console.log("HERE HERE");

      return;
    }
  }

  loadGoogleClient() {
    const w = window as any;

    if (
      !w.google ||
      !w?.google?.accounts?.oauth2?.initTokenClient ||
      this.state.googleClient ||
      Capacitor.isNativePlatform()
    ) {
      return;
    }

    let client: any = w.google.accounts.oauth2.initTokenClient({
      client_id: Constants.GOOGLE_CLIENT_ID,
      scope: Constants.GOOGLE_CLIENT_SCOPES,
      prompt: "consent",

      callback: (response: any) => {
        API.signInWithGoogleLeads(response)
          .then(
            (data: any) => {
              let payload = data?.data?.data;

              this.handleOAuthRedirect(payload);
            },
            (e: any) => {}
          )
          .finally(() => {
            this.setState({
              submitting: false,
            });
          });
      },
      error_callback: (e: any) => {
        console.error(e);

        this.setState({
          submitting: false,
        });

        // TODO: error handler here
      },
    });

    this.setState({
      googleClient: client,
    });
  }

  async checkSession() {
    const user = await StorageUtils.get("user_customer");
    const authToken = await StorageUtils.get("user_authorizationToken");

    let urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has("supportLogin") && urlParams.has("supportToken")) {
      let token = urlParams.get("supportToken");
      let customer: any = urlParams.get("customer");

      try {
        customer = JSON.parse(customer);

        if (customer) {
          this.handleOAuthRedirect({
            redirect: "active_member",
            customer,
            authorizationToken: token,
            email: customer?.email,
          });
        }
      } catch (e) {}

      return;
    }

    if (user && authToken) {
      this.props.history.push("/manage/home");
    }
  }

  componentDidMount(): void {
    this.handleTransition();
    //this.loadGoogleClient();

    this.checkSession();
  }

  componentWillUnmount(): void {
    if (this.state.slideInterval) {
      clearInterval(this.state.slideInterval);
    }
  }

  /**
   * Handle sign in with Google attempts
   *
   * @returns
   */
  signInWithGoogle() {
    if (!this.state.googleClient) {
      return;
    }

    this.setState({
      submitting: true,
    });

    this.state.googleClient.requestAccessToken();
  }

  signInWithApple() {
    let nonce = StringUtils.requestID();
    let clientId = Capacitor.isNativePlatform()
      ? "com.projectleannation.app"
      : "com.projectleannation.appweb";

    let options: any = {
      clientId,
      redirectURI: window.location.href,
      scopes: "email name",
      state: "",
      nonce,
    };

    if (!Capacitor.isNativePlatform()) {
      options.usePopup = true;
    }

    this.setState({
      submitting: true,
    });

    // APPLE KEY ID:K9Y6LQ8QFJ

    SignInWithApple.authorize(options)
      .then((result: SignInWithAppleResponse) => {
        // Handle user information
        // Validate token with server and create new session

        let resp: any = result?.response;

        API.signInWithAppleLeads(resp, nonce, clientId).then(
          (data: any) => {
            let payload = data?.data?.data;

            this.handleOAuthRedirect(payload);
          },
          (e: any) => {
            console.error(e);
          }
        );
      })
      .catch((error) => {
        // Handle error
      })
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  componentDidUpdate(
    prevProps: Readonly<{}>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    if (!this.state.googleClient && window.google) {
      //this.loadGoogleClient();
    }
  }

  setError(message: string) {
    this.setState({
      error: message,
    });

    if (message) {
      setTimeout(() => {
        this.setError("");
      }, 7000);
    }
  }

  checkForAccount(email: any) {
    if (!email || !StringUtils.isValidEmail(email)) {
      return;
    }

    this.setState({
      newAccount: false,
    });

    API.authenticateCustomer(encodeURIComponent(email))
      .then(
        (data: any) => {
          const resp = data?.data?.data;

          //console.log(resp);

          if (resp?.requireAuthentication && !resp?.needsPassword) {
            this.setState({
              showPassword: true,
              allowEmailSignIn: true,
              requirePassword: true,
            });

            return;
          }

          if (resp?.oauthSignInSupported && resp?.needsPassword) {
            //console.log(resp);

            this.setError(
              `You created your account using ${resp?.oauthSignInMethods?.join(
                " and "
              )}. Please sign in with ${
                resp?.oauthSignInMethods?.length > 1 ? "either " : ""
              }${resp?.oauthSignInMethods?.join(" or ")} to continue.`
            );

            return;
          }

          if (resp?.needsPassword) {
            // TODO: send into password onboarding mode

            /*  console.log(
              "HERE",
              !this.state.email,
              !StringUtils.isValidEmail(this.state.email),
              this.state.submitting,
              !this.state.allowEmailSignIn,
              this.state.requirePassword && !this.state.password
            );*/

            this.setState({
              allowEmailSignIn: true,
              customer: resp?.customer,
              requirePassword: false,
            });
          }
        },
        (e: any) => {
          console.log(e);

          if (
            (e?.response?.httpStatus == 410 || e.response?.status == 410) &&
            Capacitor.getPlatform() == "ios"
          ) {
            this.setError(
              "Unable to find an online account for your email. Please contact your store for assistance."
            );

            return;
          }

          this.setState({
            showPassword: false,
            requirePassword: false,
            allowEmailSignIn: false,
            newAccount: true,
          });
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  continue() {
    if (this.state.newAccount) {
      StorageUtils.set("checkout_email", this.state.email).then(() => {
        if (Capacitor.isNativePlatform()) {
          LinkUtils.openInNewTab(
            "https://account.projectleannation.com/start?email=" +
              this.state.email
          );

          return;
        }

        this.props.history.push("/start?email=" + this.state.email);
      });

      return;
    }

    if (this.state.showPassword) {
      this.setState({
        submitting: true,
      });

      API.authenticateCustomerWithPassword(
        this.state.email?.toLowerCase(),
        this.state.password
      )
        .then(
          (data: any) => {
            let resp = data?.data?.data;

            resp.redirect = "active_member";

            // console.log(resp);

            this.handleOAuthRedirect(resp);
          },
          (e) => {
            console.error(e);

            this.setError(
              `Unable to sign in. ${
                e?.response?.data?.message
                  ? e?.response?.data?.message
                  : "Unknown error occurred, please try again."
              }`
            );

            SegmentUtils.track("User Signin Failed", {
              error_message: `Unable to sign in. ${
                e?.response?.data?.message
                  ? e?.response?.data?.message
                  : "Unknown error occurred, please try again."
              }`,
              method: "password",
            });
          }
        )
        .finally(() => {
          this.setState({
            submitting: false,
          });
        });

      return;
    }

    this.setState({
      mode: "setpassword",
      newPassword: "",
    });
  }

  /**
   * Set the password for a new account
   *
   * @param email
   * @param password
   */
  setAccountPassword(email: any, password: any) {
    if (!email || !password || password?.length < 8) {
      return;
    }

    this.setState({
      submitting: true,
    });

    API.setPasswordNewAccount(email, password).then(
      (data) => {
        /*swal({
            title: "Your password has been updated",
            text: "You can now login to your account using your new password.",
            icon: "success",
          });*/

        API.authenticateCustomerWithPassword(email, password)
          .then(
            (data: any) => {
              this.props.history.push("/manage/home");

              let payload: any = {
                authorizationToken: data?.data?.data?.authorizationToken,
                redirect: "active-member",
              };

              this.handleOAuthRedirect(payload);
            },
            (e) => {
              // TODO: handle error here

              this.setError(
                `We're having trouble signing you in. ${
                  e?.response?.data?.message
                    ? e?.response?.data?.message
                    : "Unknown error occurred, please try again."
                }`
              );
            }
          )
          .finally(() => {
            this.setState({
              submitting: false,
            });
          });
      },
      (e) => {
        this.setState({
          submitting: false,
        });

        if (e?.response?.httpStatus == 409 || e.response?.status == 409) {
          this.setError(
            "Your account already has a password set. Please sign in to continue"
          );

          return;
        }

        if (
          (e?.response?.httpStatus == 410 || e.response?.status == 410) &&
          Capacitor.getPlatform() == "ios"
        ) {
          this.setError(
            "Unable to find an online account for your email. Please contact your store for assistance."
          );

          return;
        }

        this.setError(
          "We're having trouble finding your account. Please try again."
        );
      }
    );
  }

  render() {
    return (
      <IonPage>
        <SEOHeaders title="Sign In"></SEOHeaders>
        {Capacitor.isNativePlatform() && (
          <>
            <IonHeader>
              <IonToolbar className="pln-native-toolbar">
                <IonTitle>Sign In</IonTitle>
              </IonToolbar>
            </IonHeader>
          </>
        )}
        <IonContent className="ion-content-bgwhite">
          <div className="pb-lg-4 px-[1rem] md:px-0 min-h-[80vh] md:min-h-[86vh]">
            {/** Desktop View */}
            <div className="flex justify-center my-5">
              <div className="text-center">
                <img
                  src={plnSymbol}
                  alt="Project LeanNation Logo"
                  className="h-[70px] w-[70px] md:w-[80px] md:h-[80px] mx-auto"
                ></img>
              </div>
            </div>
            <div
              style={{
                maxWidth: 600,
                margin: "auto",
                overflow: "hidden",
              }}
              className="rounded bg-white md:shadow rounded-lg border-[1px] border-gray-100 mt-5 md:px-5 md:py-5"
            >
              <h1 className="mt-5 mb--3 mb-0 text-center font-weight-bold text-2xl sm:text-3xl  tracking-tight">
                {this.state.mode == "setpassword"
                  ? "Welcome To Project LeanNation!"
                  : "Let's sign you in"}
              </h1>
              <div className="px-4 pt-5 pb-5 text-center d-flex ion-justify-content-center sm-align-items-center">
                {this.state.mode == "setpassword" ? (
                  <>
                    <div>
                      <p
                        className="small text-gray-900 mb-4"
                        style={{ lineHeight: 1.3 }}
                      >
                        Let's get started by adding a password to your account.
                        Your password must be 8+ characters long.
                      </p>
                      <div>
                        <form
                          onSubmit={(e: any) => {
                            e.preventDefault();

                            this.setAccountPassword(
                              this.state.email,
                              this.state.newPassword
                            );
                          }}
                        >
                          <div className="mt-3">
                            {this.state.error && (
                              <div className="mb-3 text-danger">
                                {this.state.error}
                              </div>
                            )}

                            <div className={`relative mb-4`}>
                              <label
                                htmlFor="newPassword"
                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs sm:text-sm font-medium text-gray-900"
                              >
                                Password
                              </label>
                              <input
                                auto-capitalize="none"
                                name="newPassword"
                                id="newPassword"
                                type={
                                  this.state.viewPassword ? "text" : "password"
                                }
                                autoComplete="new-password"
                                className={`block w-full outline-0 rounded border-[1px] px-[0.75rem] py-[0.75rem] sm:px-3 sm:py-3 text-gray-900 shadow-sm ring-0 ring-inset ring-orange-500 placeholder:text-gray-400 text-sm sm:text-base sm:leading-6`}
                                placeholder="Password"
                                value={this.state.newPassword}
                                required={true}
                                onChange={(event: any) => {
                                  this.setState({
                                    newPassword: event?.target?.value,
                                  });
                                }}
                              />
                              <div className="absolute inset-y-0 right-0 flex py-1.5 pr-[0.75rem]">
                                <button
                                  type="button"
                                  className="focus:outline-none text-xs text-gray-900"
                                  onClick={() => {
                                    this.setState({
                                      viewPassword: !this.state.viewPassword,
                                    });
                                  }}
                                >
                                  {this.state.viewPassword ? "Hide" : "Show"}
                                </button>
                              </div>
                            </div>

                            <IonButton
                              type="submit"
                              className="pln-button-web"
                              mode="ios"
                              expand={"block"}
                              color="primary"
                              disabled={
                                !this.state.email ||
                                !StringUtils.isValidEmail(this.state.email) ||
                                this.state.submitting ||
                                !this.state.allowEmailSignIn ||
                                (this.state.requirePassword &&
                                  !this.state.password)
                              }
                              onClick={() => {
                                this.continue();
                              }}
                            >
                              {this.state.submitting ? (
                                <>
                                  <IonSpinner name="dots"></IonSpinner>
                                </>
                              ) : (
                                <>Continue</>
                              )}
                            </IonButton>
                            <button
                              type="submit"
                              style={{
                                position: "absolute",
                                visibility: "hidden",
                              }}
                            >
                              submit
                            </button>
                            <div
                              className="mt-3 text-center text-gray-500"
                              style={{
                                fontSize: 12,
                              }}
                            >
                              By clicking Continue, you agree to our{" "}
                              <a
                                href="https://google.com"
                                target={"_blank"}
                                rel="noreferrer"
                                className="text-gray-700"
                              >
                                Terms of Service
                              </a>
                              ,{" "}
                              <a
                                href="https://google.com"
                                target={"_blank"}
                                rel="noreferrer"
                                className="text-gray-700"
                              >
                                Privacy Policy
                              </a>
                              .
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      {this.state.newAccount ? (
                        <p
                          className="small text-gray-900 mb-4 tracking-tight"
                          style={{ lineHeight: 1.3 }}
                        >
                          We couldn't find an account associated with your
                          email. Let's get you signed up!<br></br>
                          <br></br>Already have an account?{" "}
                          <Link
                            to="/login"
                            onClick={(e: any) => {
                              e.preventDefault();

                              this.setState({
                                email: "",
                                newAccount: false,
                              });
                            }}
                            className="text-primary"
                          >
                            Use a different email address
                          </Link>
                        </p>
                      ) : (
                        <p
                          className="small text-gray-900 mb-4"
                          style={{ lineHeight: 1.3 }}
                        >
                          Enter your email below to continue.
                        </p>
                      )}
                      <div>
                        {/**
                               * <div>
                                <IonGrid className="px-0">
                                  <IonRow>
                                    <IonCol
                                      size-xs={12}
                                      size-sm={12}
                                      className="pl-0 pr-0"
                                    >
                                      <button
                                        className="mt-2 signin-with-google-btn"
                                        style={{}}
                                        onClick={this.signInWithGoogle.bind(
                                          this
                                        )}
                                      >
                                        <div
                                          style={{
                                            position: "relative",
                                            top: 1,
                                          }}
                                        >
                                          <svg
                                            style={{
                                              height: 18,
                                              width: 18,
                                              marginRight: "0.5rem",
                                            }}
                                            version="1.1"
                                            viewBox="0 0 48 48"
                                          >
                                            <g>
                                              <path
                                                fill="#EA4335"
                                                d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                                              ></path>
                                              <path
                                                fill="#4285F4"
                                                d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                                              ></path>
                                              <path
                                                fill="#FBBC05"
                                                d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                                              ></path>
                                              <path
                                                fill="#34A853"
                                                d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                                              ></path>
                                              <path
                                                fill="none"
                                                d="M0 0h48v48H0z"
                                              ></path>
                                            </g>
                                          </svg>
                                        </div>
                                        <div
                                          style={{
                                            fontWeight: 500,
                                            fontSize: 16,
                                            color: "var(--dark)",
                                            lineHeight: 1.2,
                                          }}
                                        >
                                          Continue with Google
                                        </div>
                                      </button>
                                    </IonCol>
                                    <IonCol
                                      size-xs={12}
                                      size-sm={12}
                                      className="pr-0 pl-0"
                                    >
                                      <button
                                        className="mt-1 mt-sm-0 mt-md-2 signin-with-google-btn"
                                        style={{}}
                                        onClick={this.signInWithApple.bind(
                                          this
                                        )}
                                      >
                                        <div
                                          style={{
                                            position: "relative",
                                            top: 0,
                                          }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="814"
                                            height="1000"
                                            viewBox="0 0 814 1000"
                                            style={{
                                              height: 18,
                                              width: 15,
                                              marginRight: "0.6rem",
                                            }}
                                          >
                                            <path
                                              fill="#000000"
                                              d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"
                                            />
                                          </svg>
                                        </div>
                                        <div
                                          style={{
                                            fontWeight: 500,
                                            fontSize: 16,
                                            color: "var(--dark)",
                                            lineHeight: 1.2,
                                          }}
                                        >
                                          Continue with Apple
                                        </div>
                                      </button>
                                    </IonCol>
                                  </IonRow>
                                </IonGrid>
                              </div>
                              <div
                                className="mt-3 mb-4 pb-1 text-medium font-weight-500 text-center"
                                style={{ fontSize: 14 }}
                              >
                                or continue with email
                              </div>
                               */}
                        <form
                          onSubmit={(e: any) => {
                            e.preventDefault();

                            this.continue();
                          }}
                        >
                          <div className="mt-3">
                            {this.state.error && (
                              <div className="mb-3 text-danger">
                                {this.state.error}
                              </div>
                            )}

                            <div className="relative mb-4">
                              <label
                                htmlFor="email"
                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs sm:text-sm font-medium text-gray-900"
                              >
                                Email
                              </label>
                              <input
                                auto-capitalize="none"
                                type="text"
                                name="email"
                                id="email"
                                autoComplete="email"
                                className="block w-full outline-0 rounded border-[1px] px-[0.75rem] py-[0.75rem] sm:px-3 sm:py-3 text-gray-900 shadow-sm ring-0 placeholder:text-gray-400 text-sm sm:text-base sm:leading-6"
                                placeholder="your@email.com"
                                value={this.state.email}
                                required={true}
                                onChange={(event: any) => {
                                  let typingListener =
                                    this.state.typingListener;

                                  if (
                                    StringUtils.isValidEmail(
                                      event?.target?.value
                                    )
                                  ) {
                                    this.setState({
                                      submitting: true,
                                    });
                                  }

                                  if (typingListener) {
                                    clearTimeout(typingListener);
                                  }

                                  this.setState(
                                    {
                                      email: event?.target?.value,
                                    },
                                    () => {
                                      typingListener = setTimeout(() => {
                                        this.checkForAccount(this.state.email);
                                      }, 500);

                                      this.setState({
                                        typingListener,
                                      });
                                    }
                                  );
                                }}
                              />
                            </div>
                            <div
                              className={`${
                                !this.state.showPassword && "d-none"
                              }  relative mb-4`}
                            >
                              <label
                                htmlFor="email"
                                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs sm:text-sm font-medium text-gray-900"
                              >
                                Password
                              </label>
                              <input
                                auto-capitalize="none"
                                name="password"
                                id="password"
                                type={
                                  this.state.viewPassword ? "text" : "password"
                                }
                                autoComplete="password"
                                className={`block w-full outline-0 rounded border-[1px] px-[0.75rem] py-[0.75rem] sm:px-3 sm:py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-orange-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-500 text-sm sm:text-base sm:leading-6`}
                                placeholder="Password"
                                value={this.state.password}
                                required={true}
                                onChange={(event: any) => {
                                  this.setState({
                                    password: event?.target?.value,
                                  });
                                }}
                              />
                              <div className="absolute inset-y-0 right-0 flex py-1.5 pr-[0.75rem]">
                                <button
                                  type="button"
                                  className="focus:outline-none text-xs text-gray-900"
                                  onClick={() => {
                                    this.setState({
                                      viewPassword: !this.state.viewPassword,
                                    });
                                  }}
                                >
                                  {this.state.viewPassword ? "Hide" : "Show"}
                                </button>
                              </div>
                            </div>

                            <div
                              className={`mb-4 mt--1 ${
                                !this.state.showPassword && "d-none"
                              }`}
                            >
                              <Link to="/login/forgot">
                                Forgot Your Password?
                              </Link>
                            </div>

                            <IonButton
                              type="submit"
                              className="pln-button-web"
                              mode="ios"
                              expand={"block"}
                              color="primary"
                              disabled={
                                !this.state.email ||
                                !StringUtils.isValidEmail(this.state.email) ||
                                this.state.submitting ||
                                (!this.state.allowEmailSignIn &&
                                  !this.state.newAccount) ||
                                (this.state.requirePassword &&
                                  !this.state.password)
                              }
                              onClick={() => {
                                this.continue();
                              }}
                            >
                              {this.state.submitting ? (
                                <>
                                  <IonSpinner name="dots"></IonSpinner>
                                </>
                              ) : (
                                <>
                                  {this.state.newAccount
                                    ? "Get Started"
                                    : "Continue"}
                                </>
                              )}
                            </IonButton>

                            <button
                              type="submit"
                              style={{
                                position: "absolute",
                                visibility: "hidden",
                              }}
                            >
                              submit
                            </button>
                            <div
                              className="mt-3 text-center text-gray-500"
                              style={{
                                fontSize: 12,
                              }}
                            >
                              By clicking Continue, "Continue with Google", or
                              "Continue with Apple", you agree to our{" "}
                              <a
                                href="https://www.iubenda.com/terms-and-conditions/76912715"
                                rel="noreferrer"
                                onClick={(e: any) => {
                                  e.preventDefault();

                                  LinkUtils.openInNewTab(
                                    "https://www.iubenda.com/terms-and-conditions/76912715"
                                  );
                                }}
                                className="text-gray-700"
                              >
                                Terms of Service
                              </a>
                              ,{" "}
                              <a
                                href="https://www.iubenda.com/privacy-policy/76912715"
                                rel="noreferrer"
                                onClick={(e) => {
                                  e.preventDefault();

                                  LinkUtils.openInNewTab(
                                    "https://www.iubenda.com/privacy-policy/76912715"
                                  );
                                }}
                                className="text-gray-700"
                              >
                                Privacy Policy
                              </a>
                              .
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div
              className="py-4"
              style={{
                maxWidth: 600,
                margin: "auto",
                overflow: "hidden",
              }}
            >
              <button
                onClick={() => {
                  if (Capacitor.isNativePlatform()) {
                    LinkUtils.openInNewTab(
                      "https://account.projectleannation.com/start"
                    );

                    return;
                  }

                  this.props.history.push("/start");
                }}
                className="rounded-lg p-[0.75rem] md:p-3 bg-[#FFFFFF] text-gray-900 hover:text-orange-500 transition-all w-full text-center font-medium text-sm sm:text-base md:text-lg"
              >
                New To Project LeanNation? Sign Up Now{" "}
                <IonIcon
                  style={{ position: "relative", top: 4 }}
                  icon={arrowForward}
                ></IonIcon>
              </button>
            </div>
          </div>
          <div className="relative bottom-[0px] w-full">
            <Footer></Footer>
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

export default Login;
