import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonPopover,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import Calendar from "react-calendar";

import "./AppointmentInformation.css";

import moment from "moment-timezone";
import _ from "underscore";
import { chevronBack, chevronForward, time, timeOutline } from "ionicons/icons";
import SchedulingHourButton from "../SchedulingHourButton/SchedulingHourButton";
import API from "../../../lib/API";
import WindowUtils from "../../../lib/WindowUtils";
import Event from "../../../lib/Event";
import PubSub from "../../../lib/PubSub";
import StringUtils from "../../../lib/StringUtils";
import Constants from "../../../lib/Constants";

import appleCalendar from "../../../assets/img/icons/appleCalendar.svg";
import googleCalendar from "../../../assets/img/icons/googleCalendar.svg";
import yahooCalendar from "../../../assets/img/icons/yahooCalendar.svg";
import outlookCalendar from "../../../assets/img/icons/outlookCalendar.svg";
import { useHistory } from "react-router";

const AppointmentInformation: React.FC<{
  appointment?: any;
  forceStack?: any;
  onChange?: any;
  onOpenReschedule?: any;
  onCancel?: any;
}> = ({
  appointment = null,
  forceStack = false,
  onChange = null,
  onOpenReschedule = null,
  onCancel = null,
}) => {
  const history = useHistory();
  const [presentAlert] = useIonAlert();
  const [cancelling, setCancelling] = useState(false);

  const generateGoogleMapsLink = (store: any) => {
    return (
      "https://www.google.com/maps/search/?api=1&query=" +
      "Project LeanNation " +
      store?.address_1 +
      (store?.address_2 ? " " + store?.address_2 : "") +
      ", " +
      store?.city +
      " " +
      store?.state +
      " " +
      store?.zip
    );
  };

  const selectedDateStartMoment: any = useMemo(() => {
    //console.log(appointment);

    let selectedDateStartMoment = moment(appointment?.startsAt);

    return selectedDateStartMoment;
  }, [appointment?.startsAt]);
  const selectedDateEndMoment: any = useMemo(() => {
    let selectedDateEndMoment = moment(appointment?.endsAt);

    return selectedDateEndMoment;
  }, [appointment?.endsAt]);

  const cancelAppointment = () => {
    presentAlert({
      header: "Cancel Appointment",
      message: `Are you sure you want to cancel your ${appointment?.name} appointment?`,
      buttons: [
        {
          text: "Nevermind",
          role: "cancel",
          cssClass: "text-dark",
        },
        {
          text: "Cancel Appointment",
          role: "destructive",
          handler: () => {
            setCancelling(true);

            API.cancelAppointment(appointment?._id)
              .then((data: any) => {
                if (typeof onChange == "function") {
                  onChange(data?.data?.data?.appointment);
                }

                if (typeof onCancel == "function") {
                  onCancel(data?.data?.data?.appointment);
                }
              })
              .finally(() => {
                setCancelling(false);
              });
          },
        },
      ],
      mode: "ios",
    });
  };

  return (
    <>
      <IonRow>
        <IonCol
          size-xs="12"
          size-md={forceStack ? "12" : "6"}
          className={`pl-0 pr-0 ${forceStack ? "" : "pr-md-2"}`}
        >
          <div className="">
            {appointment?.status == "CANCELLED" ? (
              <h1 className="mt-0">Your Appointment Has Been Cancelled</h1>
            ) : (
              <h1 className="mt-0">Your Appointment Is Confirmed!</h1>
            )}
            <h5 className="m-0">{appointment?.name}</h5>

            <p className="mb-0 mt-1 text-dark">
              {selectedDateStartMoment?.format("dddd MMMM DD, YYYY")},{" "}
              {selectedDateStartMoment?.format("hh:mm A")}
              &nbsp;-&nbsp;
              {selectedDateEndMoment?.format("hh:mm A")}
            </p>

            <p className="mb-0 mt-0 text-dark font-weight-500"></p>
            {appointment?.status != "CANCELLED" ? (
              <div className="pt-4">
                <div className="mb-3">
                  <div className="shadow--hover d-inline-block">
                    <a href={appointment?.googleCalendarURL} target="_blank">
                      <img src={googleCalendar} style={{ height: 39 }}></img>
                    </a>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="shadow--hover d-inline-block">
                    <a
                      href={`data:text/calendar;base64,${btoa(
                        appointment?.icsAttachment
                      )}`}
                      download={"appointment.ics"}
                      target="_blank"
                    >
                      <img src={appleCalendar} style={{ height: 39 }}></img>
                    </a>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="shadow--hover d-inline-block">
                    <a href={appointment?.outlookCalendarURL} target="_blank">
                      <img src={outlookCalendar} style={{ height: 39 }}></img>
                    </a>
                  </div>
                </div>
                <div className="">
                  <div className="shadow--hover d-inline-block">
                    <a href={appointment?.yahooCalendarURL} target="_blank">
                      <img src={yahooCalendar} style={{ height: 39 }}></img>
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              <div className="mt-4"></div>
            )}
            <div className="pt-3 border-top"></div>
          </div>
        </IonCol>
        <IonCol
          size-xs="12"
          size-md={forceStack ? "12" : "6"}
          className={`pl-0 pr-0 mt-3 ${forceStack ? "" : "pr-md-2 mt-md-0"}`}
        >
          <div
            className="p-3"
            style={{ border: "1px solid var(--midlighter)", borderRadius: 6 }}
          >
            <h3 className="text-dark mb-3 mt-0">Appointment Information</h3>

            <div className="mb-3">
              <p className="mt-0 mb-2 font-weight-500">Scheduled For:</p>
              <h5 className="m-0">{appointment?.email}</h5>
            </div>
            <div className="mb-3">
              <p className="mt-0 mb-2 font-weight-500">Location:</p>
              <h5 className="mb-1 mt-0">
                {appointment?.store?.location?.address?.formattedAddress}
              </h5>
              <a
                href={generateGoogleMapsLink(
                  appointment?.store?.location?.address
                )}
                target="_blank"
                rel="noreferrer"
              >
                <IonButton
                  size="small"
                  color="dark"
                  fill="outline"
                  className="mt-2"
                  mode="ios"
                >
                  Directions
                </IonButton>
              </a>
            </div>

            {appointment?.goal ? (
              <>
                <div className="mb-3">
                  <p className="mt-0 mb-2 font-weight-500">
                    What Can We Help You Achieve?
                  </p>
                  <h5 className="m-0">{appointment?.goal ?? "Other Goal"}</h5>
                </div>
              </>
            ) : null}
            {appointment?.additionalNotes ? (
              <>
                <div>
                  <p className="mt-0 mb-2 font-weight-500">
                    Anything Additional You'd Like To Share?
                  </p>
                  <h5 className="m-0">{appointment?.additionalNotes}</h5>
                </div>
              </>
            ) : null}
          </div>
          <IonRow className="mt-3 p-0">
            {appointment?.status != "CANCELLED" ? (
              <IonCol
                className="pl-0 pr-0 pr-sm-2 pr-lg-0"
                size-xs="12"
                size-sm="6"
                size-md="6"
                size-lg="12"
              >
                <IonButton
                  color="dark"
                  className="pln-button-web-outline"
                  expand="block"
                  fill="outline"
                  mode="ios"
                  onClick={() => {
                    if (typeof onOpenReschedule == "function") {
                      return onOpenReschedule(appointment);
                    }

                    history.push(
                      `/schedule/${appointment?.storeID}/${appointment?.appointmentType}?mode=reschedule&id=${appointment?._id}`
                    );
                  }}
                >
                  Reschedule
                </IonButton>
              </IonCol>
            ) : null}
            {appointment?.status == "CANCELLED" ? (
              <IonCol
                size-xs="12"
                size-sm="12"
                size-md="12"
                size-lg="12"
                className="pl-0 pr-0 pr-lg-0"
              >
                <IonButton
                  onClick={() => {
                    if (typeof onOpenReschedule == "function") {
                      return onOpenReschedule(appointment);
                    }

                    history.push(
                      `/schedule/${appointment?.storeID}/${appointment?.appointmentType}?mode=reschedule&id=${appointment?._id}`
                    );
                  }}
                  color="primary"
                  className="pln-button-web-outline"
                  expand="block"
                  fill="outline"
                  mode="ios"
                >
                  Reschedule
                </IonButton>
              </IonCol>
            ) : null}
            <IonCol
              size-xs="12"
              size-sm="6"
              size-md="6"
              size-lg="12"
              className="pr-0 pl-0 pl-sm-2 pl-lg-0"
            >
              <IonButton
                color={appointment?.status == "CANCELLED" ? "light" : "danger"}
                mode="ios"
                className="pln-button-web-outline"
                fill="outline"
                disabled={cancelling || appointment?.status == "CANCELLED"}
                onClick={() => {
                  cancelAppointment();
                }}
                expand="block"
              >
                {cancelling ? (
                  <>
                    <IonSpinner name="dots"></IonSpinner>
                  </>
                ) : (
                  <>
                    {appointment?.status == "CANCELLED"
                      ? "Cancelled"
                      : "Cancel"}
                  </>
                )}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonCol>
      </IonRow>
    </>
  );
};

export default AppointmentInformation;
