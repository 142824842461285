import { registerPlugin } from '@capacitor/core';

export interface SegmentPlugin {
    track(options: { event: string, properties?: any }): Promise<{ success: boolean }>;
    identify(options: { id: string, traits?: any }): Promise<{ success: boolean }>;
    screen(options: { name: string, properties?: any, category?: string }): Promise<{ success: boolean }>;
}

const Segment = registerPlugin<SegmentPlugin>('Segment');

export default Segment;